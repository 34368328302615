<template>
<div>
       <div ref="navbar" class="navbar-custom">
            <ul class="list-unstyled topnav-menu topnav-menu-left">
                <li>
                    <ol class="breadcrumb">
                        <!--li class="breadcrumb-title">About</li>
                        <li class="breadcrumb-bar">|</li-->
                        <li class="breadcrumb-item"><a @click="$router.push('/')" href="#">HOME</a></li>
                        <li class="breadcrumb-item active">Web Accounts</li>
                    </ol>
                </li>
            </ul>
        </div>
     <div class="login-area" style="height:80vh;display:flex; align-items: center;">
      <!--div class="login" >
        <div class="login_logo"><img src="@/assets/images/Common/logo-login.png" alt="logo"></div>
        <p class="wi_plat">WI.Plat</p>
        <p class="title"><span class="bold">NELOW</span><span class="bar">|</span>NEVER LOSE WATER</p>

        <div style=" width : 1000px ; height: 250px;  margin:0 auto; padding:0px 0px 0px 0px; ">
        <div  style=" width : 1000px ;box-shadow: 1px 1px 1px #bcbcbc;margin:0 auto;  border: 1px solid #bcbcbc;padding:20px 0px 20px 40px;  ">
          <b-row> <p> PRODUCT : NELOW (Never Lose Water) </p></b-row>
          <b-row> <p> VERSION : 2.0</p></b-row>
          <b-row><p> Company : Wi.Plat</p></b-row>
          <b-row><p> Web : <a href="http://wiplat.com" target="_blank"  ><p>wiplat.com</p></a></p></b-row>
          <b-row>  <p> Support : 042-382-7905 </p></b-row>
        </div>
        </div>
      </div-->
        <div class="container av-section-cont-open">
        <div class="template-page content av-content-full alpha units av-enable-shadow">
        <div class="post-entry post-entry-type-page post-entry-39">
        <div class="entry-content-wrapper clearfix">
        <!--div class="flex_column av-1euyz-8537076e6b0c1207de4c2a57bb4a8eb0 av_one_full  avia-builder-el-32  el_before_av_one_full  avia-builder-el-first  first flex_column_div  "><div class="av-special-heading av-lnvqoqt2-7530e87e956f2790720b8faae62c0364 av-special-heading-h2 blockquote modern-quote modern-centered  avia-builder-el-33  avia-builder-el-no-sibling  main_title_style_h2"><h2 class="av-special-heading-tag" itemprop="headline"><span class="title_color_style1"> Implementation Performance</span></h2><div class="special-heading-border"><div class="special-heading-inner-border"></div></div></div></div><div class="flex_column av-svzz-3bdaef3d0c3098c62b2837f6fb0e74af av_one_full  avia-builder-el-34  el_after_av_one_full  avia-builder-el-last  img_text_style first flex_column_div  column-top-margin"><section class="av_textblock_section av-lnvqpebj-decf3768ccb39593b973155eadf91ae4 " itemscope="itemscope" itemtype="https://schema.org/CreativeWork"><div class="avia_textblock" itemprop="text"-->

        <!--h2 class="av-special-heading-tag" itemprop="headline"><span class="title_color_style1"> Servers providing services</span></h2-->
        <table id="tablepress-9" class="tablepress tablepress-id-9">
        <tbody>
        <tr class="row-1">
          <td class="column-1"> </td><td class="column-2">Server name</td> <td class="column-1"> Link</td><td class="column-3">Address</td>
        </tr>
        <tr class="row-2" v-for="(item, index) in server_name" :key="index">
          <td class="column-1">{{index+1}}</td><td class="column-2">{{item.text }}</td>
          <td class="column-2"> <a :href="item.value" target="_blank" title="새창으로 열기"> <i class="mdi mdi-open-in-new"></i></a></td>
          <td class="column-3">
            <span class="btnwhite large">
            {{item.value}}
           </span>
          </td>
        </tr>

        </tbody>
        </table>
        <!-- #tablepress-9 from cache >
        </div></section></div -->
        <p></p>
        </div></div></div><!-- close content main div --> <!-- section close by builder template -->
        </div>
    </div>      
  
 </div>
</template>
<script>
export default {
 data() {
    return {
      server_name : [],
    }
 },
 methods: {
 },
 mounted() {
   this.$http.get(this.URL +'api/get_server_list').then((rows) => {
        if((!rows)||(rows.data.result<=0)) return;
        for(let i=0; i<rows.data.result.length; i++) {
         var server_addr =  rows.data.result[i].server_host
         if(server_addr !== undefined && server_addr !== null ){
           server_addr = server_addr.replace("/node/api/", ""); 
         }
         this.server_name.push({value:server_addr, text :rows.data.result[i].server_name}) ;
        }          
    })
 },
};
</script>  
<style scoped lang="scss">
a {
 color : white;
}
 .container {
    max-width: 1000px;
}
.container {
    padding: 30px 15px !important;
	margin :auto  !important;
}
.container {
    width: 100%;
}
.avia-section .template-page {
    width: 100%;
    border: none;
    margin-left: auto;
    padding-left: 0;
    margin-right: auto;
}
.tablepress {
    --head-active-bg-color: #049cdb;
    --head-active-text-color: var(--head-text-color);
}
.tablepress {
    --text-color: #111;
    --head-text-color: var(--text-color);
    --head-bg-color: #d9edf7;
    --odd-text-color: var(--text-color);
    --odd-bg-color: #f9f9f9;
    --even-text-color: var(--text-color);
    --even-bg-color: #fff;
    --hover-text-color: var(--text-color);
    --hover-bg-color: #f3f3f3;
    --border-color: #ddd;
    --padding: 0.5rem;
    border: none;
    border-collapse: collapse;
    border-spacing: 0;
    clear: both;
    margin: 0 auto 1rem;
    table-layout: auto;
    width: 100%;
}
table#tablepress-9 tbody tr.row-1 td.column-1 {
   // background: #6fa8dc;3b4d7c
    background: #3b4d7c ;
    color: white !important;
    //color: black !important;
    width: 60px;
    border: 1px solid #5e8af7;
}
table#tablepress-9 tbody tr.row-1 td {
    //background: #164793;
    background: #3b4d7c;
    color: white !important;
    border: 1px solid #5e8af7;
}
table#tablepress-9 tbody tr td.column-1 {
    background: #3b4d7c;
    color: white !important;
    border: 1px solid #5e8af7;
}
table#tablepress-9 tbody tr td {
    border: 1px solid gray;
    text-align: center;
    vertical-align: middle;
    font-size: 18px;
    color: white !important;
    background: #172a46;
    padding: 13px 10px;
}

</style>
